import { createSlice } from "@reduxjs/toolkit";
import {
  fetchClasses,
  fetchClassById,
  addStudentToClass,
  removeStudentFromClass,
  addClass,
} from "./classesThunks"; // Import the new thunks

const initialState = {
  classes: {
    data: [],
    status: "idle",
    error: null,
    totalCount: null,
    currentPage: 1,
    totalPages: 1,
  },
  singleClass: {
    data: null,
    status: "idle",
    error: null,
  },
  addClass: {
    status: "idle", // Loading status for adding a new class
    error: null, // Error state for adding a new class
  },
};

const classesSlice = createSlice({
  name: "classes",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchClasses.pending, (state) => {
        state.classes.status = "loading";
      })
      .addCase(fetchClasses.fulfilled, (state, action) => {
        state.classes.status = "succeeded";
        state.classes.data = action.payload.data;
        state.classes.totalPages = action.payload.totalPages;
        state.classes.currentPage = action.payload.currentPage;
        state.classes.totalCount = action.payload.totalCount;
      })
      .addCase(fetchClasses.rejected, (state, action) => {
        state.classes.status = "failed";
        state.classes.error = action.error.message;
      })
      .addCase(fetchClassById.pending, (state) => {
        state.singleClass.status = "loading";
        state.singleClass.error = null;
      })
      .addCase(fetchClassById.fulfilled, (state, action) => {
        state.singleClass.status = "succeeded";
        state.singleClass.data = action.payload;
      })
      .addCase(fetchClassById.rejected, (state, action) => {
        state.singleClass.status = "failed";
        state.singleClass.error = action.error.message;
      })
      .addCase(addStudentToClass.pending, (state) => {
        state.singleClass.status = "loading";
        state.singleClass.error = null;
      })
      .addCase(addStudentToClass.fulfilled, (state, action) => {
        state.singleClass.status = "succeeded";
        // Optionally update the class data to reflect the newly added student
        if (state.singleClass.data && state.singleClass.data.students) {
          state.singleClass.data.students.push(action.payload.student);
        }
      })
      .addCase(addStudentToClass.rejected, (state, action) => {
        state.singleClass.status = "failed";
        state.singleClass.error = action.error.message;
      })
      // Handle addClass
      .addCase(addClass.pending, (state) => {
        state.addClass.status = "loading";
        state.addClass.error = null;
      })
      .addCase(addClass.fulfilled, (state, action) => {
        state.addClass.status = "succeeded";
        // Safely update the array
        if (Array.isArray(state.classes.data)) {
          state.classes.data.push(action.payload); // Push the new class object to the classes list
        } else {
          state.classes.data = [action.payload]; // Initialize as a new array if it was not defined
        }
      })
      .addCase(addClass.rejected, (state, action) => {
        state.addClass.status = "failed";
        state.addClass.error = action.payload || action.error.message;
      })
      .addCase(removeStudentFromClass.pending, (state) => {
        state.singleClass.status = "loading";
        state.singleClass.error = null;
      })
      .addCase(removeStudentFromClass.fulfilled, (state, action) => {
        state.singleClass.status = "succeeded";
        // Optionally update the class data to reflect the removed student
        if (state.singleClass.data && state.singleClass.data.students) {
          state.singleClass.data.students = state.singleClass.data.students.filter(
            (student) => student._id !== action.meta.arg.studentId
          );
        }
      })
      .addCase(removeStudentFromClass.rejected, (state, action) => {
        state.singleClass.status = "failed";
        state.singleClass.error = action.error.message;
      });
  },
});

export default classesSlice.reducer;
