import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClasses, addClass } from "../../store/classes/classesThunks";
import { fetchStudentsNotInClass, fetchTeachers } from "../../store/users/usersThunks";
import { useGetBranchesQuery } from "../../services";
import { Link } from "react-router-dom";
import ClassRoom from "../../assets/icons/classroom.png";
import Loading from "../../components/Loading";
import toast from "react-hot-toast"; // Importing toast

function Classes() {
    const { data: branches, isLoading: branchesLoading } = useGetBranchesQuery(); // Fetching branches with loading state

    const [branchId, setBranchId] = useState(null);
    const [openNewClassForm, setOpenNewClassForm] = useState(false);
    const { users, classes, loading: classesLoading } = useSelector((state) => state); // Adding loading state for classes
    const dispatch = useDispatch();
    console.log("branches", branches?.data[0]);

    const [newClass, setNewClass] = useState({
        name: "Ali",
        teacherId: "",
        subject: "English",
        weekType: "Odd",
        time: "08:00-10:00",
        room: "204",
        branchId: branchId,
    });

    useEffect(() => {
        if (branchId) {
            dispatch(fetchClasses({ branchId: branchId, page: 1, pageSize: 20 }));
            dispatch(fetchStudentsNotInClass({ page: 1, pageSize: 10 }));
            dispatch(fetchTeachers({ page: 1, pageSize: 10 }));
        }
    }, [dispatch, branchId]);

    useEffect(() => {
        if (branches?.data?.length && !branchId) {
            const defaultBranchId = branches.data[0]._id;
            setBranchId(defaultBranchId);
            setNewClass((prevClass) => ({ ...prevClass, branchId: defaultBranchId }));
        }
    }, [branches, branchId]);

    const handleInputChange = (e) => {
        const { name, value, type, options } = e.target;
        if (type === "select-multiple") {
            const selectedOptions = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
            setNewClass({
                ...newClass,
                [name]: selectedOptions,
            });
        } else {
            setNewClass({
                ...newClass,
                [name]: value,
            });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log("newClass: ", newClass);
            await dispatch(addClass(newClass));
            toast.success("Class created successfully!"); // Toast for successful class creation
            setOpenNewClassForm(false);
            dispatch(fetchClasses({ branchId: branchId, page: 1, pageSize: 20 }));
            setNewClass({
                name: "Ali",
                teacherId: "",
                subject: "English",
                weekType: "Odd",
                time: "08:00-10:00",
                room: "204",
                branchId: branchId,
            });
        } catch (error) {
            console.error("Error creating class:", error);
            toast.error("Failed to create class."); // Toast for failure in class creation
        }
    };

    if (branchesLoading || classesLoading) {
        return <Loading />; // Display loading component while data is loading
    }

    return (
        <div className="p-4 bg-white dark:bg-slate-700 lg:ml-64">
            <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-16">
                <div className="grid md:grid-cols-3 gap-4 mb-4">
                    {classes?.classes?.data?.map((clas) => (
                        <div
                            className="flex items-center justify-start p-4 rounded-xl bg-gray-50 dark:bg-gray-800"
                            key={clas?._id}
                        >
                            <div className="flex items-center gap-4">
                                <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                                    <img src={ClassRoom} alt="" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <h4 className="text-title-md font-bold text-black dark:text-white">
                                        {!clas?.name ? <Loading /> : clas.name}
                                    </h4>
                                    <span className="text-sm font-medium text-black dark:text-white">
                                        {clas?.students?.length ? clas?.students?.length : 0} ta{" "}
                                    </span>
                                    <Link
                                        to={`/admin/classes/${clas?._id}`}
                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                    >
                                        View
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <button
                    type="button"
                    onClick={() => setOpenNewClassForm(!openNewClassForm)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                    New Class {openNewClassForm ? "-" : "+"}
                </button>
                {openNewClassForm && (
                    <form onSubmit={handleFormSubmit} className="max-w-sm mx-auto">
                        <div className="mb-5">
                            <label
                                htmlFor="name"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Class Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                value={newClass.name}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="teacherId"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Teacher
                            </label>
                            <select
                                name="teacherId"
                                value={newClass.teacherId}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option value="">Select Teacher</option>
                                {users.teachers?.data.map((teacher) => (
                                    <option key={teacher._id} value={teacher._id}>
                                        {teacher.fullName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="subject"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Subject
                            </label>
                            <input
                                type="text"
                                name="subject"
                                value={newClass.subject}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                required
                            />
                        </div>

                        <div className="mb-5">
                            <label
                                htmlFor="weekType"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Week Type
                            </label>
                            <select
                                name="weekType"
                                value={newClass.weekType}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option value="">Select Week Type</option>
                                <option value="Odd">Odd</option>
                                <option value="Even">Even</option>
                                <option value="Both">Both</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="time"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Time
                            </label>
                            <input
                                type="text"
                                name="time"
                                value={newClass.time}
                                onChange={handleInputChange}
                                placeholder="e.g., 08:00-10:00"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="room"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Room
                            </label>
                            <input
                                type="text"
                                name="room"
                                value={newClass.room}
                                onChange={handleInputChange}
                                placeholder="e.g., A101"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Add
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default Classes;
