import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { fetchClassById, removeStudentFromClass } from "../../../store/classes/classesThunks";
import { fetchStudents, fetchStudentsNotInClass } from "../../../store/users/usersThunks";
import ClassStudents from "./ClassStudents";
import { useGetBranchesQuery } from "../../../services";
import toast from "react-hot-toast"; // Import toast

function SingleClass() {
    const dispatch = useDispatch();
    const { data: branches } = useGetBranchesQuery();

    const [branchId, setBranchId] = useState(null);
    const { id } = useParams(); // Get the classId from the URL
    const { classes } = useSelector((state) => state);
    const [classData, setClassData] = useState(null);

    useEffect(() => {
        if (id) {
            dispatch(fetchClassById(id)); // Dispatch the action to fetch the class details
            dispatch(fetchStudents({ page: 1, pageSize: 10 }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (branches?.data?.length && !branchId) {
            const defaultBranchId = branches.data[0]._id;
            setBranchId(defaultBranchId);
        }
    }, [branches, branchId]);

    useEffect(() => {
        if (classes.singleClass?.data) {
            setClassData(classes.singleClass?.data?.data); // Set the class data from the store
        }
    }, [classes.singleClass]);

    // Function to remove student from class
    const handleRemoveStudent = async (studentId) => {
        try {
            await dispatch(
                removeStudentFromClass({
                    classId: id,
                    branchId: branchId,
                    studentId,
                    monthlyFee: 100, // Example monthly fee, replace it with dynamic fee calculation if needed
                })
            ).unwrap();
            toast.success("Student removed successfully"); // Toast for successful removal
            dispatch(fetchClassById(id));
            dispatch(fetchStudentsNotInClass({ page: 1, pageSize: 10 }));
        } catch (error) {
            console.error("Error removing student from class:", error);
            toast.error("Failed to remove student from the class."); // Toast for failure
        }
    };

    return (
        <div className="p-4 bg-white dark:bg-slate-700 lg:ml-64">
            <div className="flex w-full flex-col md:flex-row">
                <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-16 flex-1 border">
                    {classData ? (
                        <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-10">
                            <div className="flex justify-end px-4 pt-4">
                                <button
                                    id="dropdownButton"
                                    className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                    type="button"
                                >
                                    <span className="sr-only">Open dropdown</span>
                                    <svg
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 16 3"
                                    >
                                        <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                                    </svg>
                                </button>
                            </div>
                            <div className="flex flex-col items-center pb-10">
                                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                                    {classData.name}
                                </h5>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                    Teacher: {classData?.teacher?.fullName}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                    Subject: {classData?.subject}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                    Days: {classData?.days?.join(", ")}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                    Time: {classData?.time}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                    Room: {classData?.room}
                                </span>
                                <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 mt-4">
                                    <li className="py-3 sm:py-4">
                                        <h2 className="font-medium">Students:</h2>
                                        <div className="flex flex-col">
                                            {classData?.students?.map((student) => (
                                                <div
                                                    key={student._id}
                                                    className="flex justify-between items-center"
                                                >
                                                    <Link
                                                        to={`/admin/users/students/${student._id}`}
                                                        className="text-sm text-blue-500 dark:text-blue-400 hover:underline"
                                                    >
                                                        {student.fullName}
                                                    </Link>
                                                    <button
                                                        onClick={() => handleRemoveStudent(student._id)}
                                                        className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-4 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <p className="text-center text-gray-500 dark:text-gray-400">
                            Loading class data...
                        </p>
                    )}
                </div>

                <ClassStudents classId={id} classData={classData} />
            </div>
        </div>
    );
}

export default SingleClass;
