import adminApiSlice from './adminApi';

export const userApiSlice = adminApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `users/allUsers`,
      providesTags: [{ type: 'User', id: 'LIST' }],
    }),
    // getClasses: builder.query({
    //   query: () => `classes/branches/66cf88693037bc9b8543adaa`,
    //   providesTags: [{ type: 'User', id: 'LIST' }],
    // }),
    blockUser: builder.mutation({
      query: (userId) => ({
        url: `blockUser/${userId}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    unblockUser: builder.mutation({
      query: (userId) => ({
        url: `unBlockUser/${userId}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    getJobSeekers: builder.query({
      query: () => 'getJobSeekersForAdmin',
      providesTags: [{ type: 'JobSeeker', id: 'LIST' }],
    }),
    getEmployers: builder.query({
      query: () => 'getEmployersForAdmin',
      providesTags: [{ type: 'Employer', id: 'LIST' }],
    }),
    getJobs: builder.query({
      query: () => 'getJobsForAdmin',
      providesTags: [{ type: 'Job', id: 'LIST' }],
    }),
    getQuickJobs: builder.query({
      query: () => 'getQuickjobsForAdmin',
      providesTags: [{ type: 'QuickJob', id: 'LIST' }],
    }),
    getCompanies: builder.query({
      query: () => 'getOfficesForAdmin',
      providesTags: [{ type: 'Company', id: 'LIST' }],
    }),
    getCompany: builder.query({
      query: ({ page = 1, limit = 10 }) =>
        `getOfficesForAdmin/?page=${page}&limit=${limit}`,
      providesTags: [{ type: 'Office', id: 'LIST' }],
    }),
    createCompany: builder.mutation({
      query: (newCompany) => ({
        url: '/offices',
        method: 'POST',
        body: newCompany,
      }),
      invalidatesTags: [{ type: 'Office', id: 'LIST' }],
    }),
    // New endpoint to fetch branches
    getBranches: builder.query({
      query: () => 'branches', // This should match your backend route
      providesTags: [{ type: 'Branch', id: 'LIST' }],
    }),
  }),
  overrideExisting: false, // Ensure that existing endpoints are not overridden
});

export const {
  useGetUsersQuery,
  useBlockUserMutation,
  useUnblockUserMutation,
  useGetJobSeekersQuery,
  useGetEmployersQuery,
  useGetJobsQuery,
  useGetQuickJobsQuery,
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useCreateCompanyMutation,
  useGetClassesQuery,
  useGetBranchesQuery, // Add this hook to use the branch fetching query
} = userApiSlice;
