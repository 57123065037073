// store/authThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Use the configured axios instance
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Helper function to set tokens in local storage
const setTokens = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
  localStorage.setItem("accessToken", data.accessToken);
  localStorage.setItem("refreshToken", data.refreshToken);
};
// Helper function to clear tokens from local storage
const clearTokens = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

// Async thunk for logging in (sends a code)
export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post("/auth/sign-in", userData);
      console.log("login: ", response.data);
      const { accessToken, refreshToken, role } = response.data.data;
      setTokens({ accessToken, refreshToken });
      return { ...response.data.data, logged: true };
      // return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (mobileToken, thunkAPI) => {
    try {
      const response = await axios.post("/auth/sign-out", {
        mobileToken: "fcmToken",
      });
      clearTokens();
      toast.success("Tizimdan chiqildi!");
      return response.data;
    } catch (error) {
      toast.error("Tizimdan chiqishda xatolik!");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
// Async thunk for signing out a role
export const signOutRole = createAsyncThunk(
  "auth/signOutRole",
  async (mobileToken, thunkAPI) => {
    try {
      console.log("signOutRole");
      const response = await axios.post("/auth/sign-out", {
        mobileToken: "fcmToken",
      });
      clearTokens();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteAccount = createAsyncThunk(
  "auth/deleteAccount",
  async () => {
    try {
      const response = await axios.delete("/auth/deleteAccount");
      clearTokens();
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
