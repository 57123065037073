import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SignUpPhone, UzbFlag } from "../../components/icon";
import useAuth from "../../hooks/useAuth";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import InputMask from "react-input-mask";
import toast from "react-hot-toast";
import UAParser from "ua-parser-js";
import axios from "axios";
import login_bg from "../../assets/img/login-bg.webp";

const SignIn = () => {
  const [phoneNumber, setPhoneNumber] = useState("+998");
  const [password, setPassword] = useState(""); // State for password
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState("");
  const [location, setLocation] = useState("");
  const { isLoading } = useSelector((state) => state.auth);
  const { userLogin, userCodeVerify } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    setDeviceInfo(result);

    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        setLocation(response.data);
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  }, []);

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value); // Update password state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Extract phone number without mask
    const rawPhoneNumber = phoneNumber.replace(/\D/g, "").slice(3, 12);
    if (rawPhoneNumber === "") {
      toast.error("Telefon raqam kiriting");
      return;
    }

    if (password === "") {
      toast.error("Parolni kiriting");
      return;
    }

    // Call login function with phone number and password
    userLogin({
      phoneNumber: rawPhoneNumber,
      password,
      mobileToken: "fcmToken",
      deviceId: "",
      deviceName: "",
      region: location?.region,
      os: JSON.stringify(deviceInfo?.os),
      browser: deviceInfo?.browser?.name,
      ip: location?.ip,
    })
      .then((res) => {
        toast.success("Kod yuborildi");
        const role =
          res?.role.toLowerCase();
        if (role === "admin") {
          navigate("/admin", { replace: true });
        } else {
          toast.error("Sizga kirishga ruxsat yo'q");
          navigate("/login", { replace: true });
          // setIsModalOpen(false);
        }
      })
      .catch((error) => {
        if (error.msg === "User not found") {
          toast.error("Foydalanuvchi topilmadi!");
        } else if (error.msg === "Invalid password") {
          toast.error("Noto'g'ri parol");
        } else {
          toast.error("Xatolik yuz berdi");
        }
      });
  };

  // const handleCodeSubmit = (code) => {
  //   const rawPhoneNumber = phoneNumber.replace(/\D/g, "").slice(3, 12);
  //   userCodeVerify({
  //     phoneNumber: rawPhoneNumber,
  //     confirmationCode: code,
  //     mobileToken: "fcmToken",
  //     deviceId: "",
  //     deviceName: "",
  //     region: location?.region,
  //     os: JSON.stringify(deviceInfo?.os),
  //     browser: deviceInfo?.browser?.name,
  //     ip: location?.ip,
  //   })
  //     .then((res) => {
  //       const role =
  //         res?.role?.charAt(0).toLowerCase() + res?.role?.slice(1) || null;
  //       if (role === "admin") {
  //         navigate("/admin", { replace: true });
  //       } else {
  //         toast.error("Sizga kirishga ruxsat yo'q");
  //         navigate("/login", { replace: true });
  //         setIsModalOpen(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <div
      style={{ backgroundImage: `url(${login_bg})` }}
      className="overflow-x-hidden h-screen bg-no-repeat bg-cover bg-[center_center] object-cover"
    >
      <div className="absolute z-1 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-full max-w-[450px]">
        <h3 className="text-[#000] text-center mb-12 text-3xl not-italic font-normal">
          LSL academy
        </h3>
        <div className="overflow-hidden bg-[#fff] p-7 my-[0] rounded-2xl">
          <h3 className="text-[#707070] text-xl not-italic font-normal leading-[140%] text-center">
            Profilga kirish
          </h3>
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="relative flex items-center mb-4">
              <div className="absolute inset-y-0 start-0 top-0 flex gap-2 items-center ps-3.5 pointer-events-none">
                <UzbFlag />
              </div>
              <InputMask
                mask="+998 (99) 999 9999"
                maskChar={null}
                value={phoneNumber}
                onChange={handleChange}
                className="border-[#F4F4F4] outline-none text-gray-900 text-sm rounded-lg block w-full ps-11 p-2.5"
                placeholder="+998 (__) ___ ____"
                alwaysShowMask
                maskPlaceholder={"Telefon raqami"}
                required
              />
            </div>

            {/* Password Input */}
            <div className="mb-4">
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                className="border-[#F4F4F4] outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Parol"
                required
              />
            </div>

            <button
              disabled={isLoading}
              type="submit"
              className="rounded-[12px] my-5 bg-[#4F7BBC] text-[#FFF] text-[20px] not-italic font-normal leading-[91.5%] w-full py-2"
            >
              Kirish
            </button>
          </form>
        </div>
      </div>
      {/* {isModalOpen && (
        <ConfirmationModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCodeSubmit}
          phoneNumber={phoneNumber}
        />
      )} */}
      <div className="absolute bg-[rgba(245,_245,_249,_0.90)] w-full h-full top-0"></div>
    </div>
  );
};

export default SignIn;
