import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./useCustomQuery";

const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "User"
  ], endpoints: builder => ({}),
})

export default apiSlice