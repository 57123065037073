import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthAdmin from "./useAdminQuery";

const adminApiSlice = createApi({
  reducerPath: "adminApi",
  baseQuery: baseQueryWithReauthAdmin,
  tagTypes: [
    "User"
  ],
  endpoints: (builder) => ({}),
});

export default adminApiSlice;
