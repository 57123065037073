import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudents, fetchUserById, fetchUsers } from "../../../store/users/usersThunks";
import { useParams, Link } from "react-router-dom";
import { fetchClasses } from "../../../store/classes/classesThunks";
import { useGetBranchesQuery } from "../../../services";

function SingleTeachers() {
    const { users, classes } = useSelector((state) => state);
    const dispatch = useDispatch();
    const { data: branches } = useGetBranchesQuery();

    const [branchId, setBranchId] = useState(null);

    const [classs, setClass] = useState();
    const singleUser = users.singleUser?.data?.data; // Corrected data structure
    const { id } = useParams();

    useEffect(() => {
        if (branches?.data?.length && !branchId) {
            const defaultBranchId = branches.data[0]._id;
            setBranchId(defaultBranchId);
        }
    }, [branches, branchId]);
    useEffect(() => {
        if (id) {
            dispatch(fetchUserById(id));
            dispatch(fetchClasses({ branchId: branchId, page: 1, pageSize: 10 }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (singleUser) {

            // Find the class id from the user's classes
            const classId = classes.data.find((classItem) => classItem.students && classItem.students.map((student) => student._id === singleUser?._id))
            setClass(classId);
        }
    }, [singleUser, classes.data]);
    // console.log("singleUser: ", singleUser);
    console.log("classId: ", classs);

    return (
        <div className="p-4 bg-white dark:bg-slate-700 lg:ml-64">
            <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-16">
                <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-10">
                    <div className="flex justify-end px-4 pt-4">
                        <button
                            id="dropdownButton"
                            className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                            type="button"
                        >
                            <span className="sr-only">Open dropdown</span>
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 16 3"
                            >
                                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex flex-col items-center pb-10">
                        <img
                            className="w-24 h-24 mb-3 rounded-full shadow-lg"
                            src={singleUser?.avatar || "/path/to/default-avatar.jpg"} // Provide a default avatar if none exists
                            alt={`${singleUser?.fullName}'s profile`}
                        />
                        <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{singleUser?.fullName}</h5>
                        <span className="text-sm text-gray-500 dark:text-gray-400">{singleUser?.phoneNumber}</span>
                        <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 mt-4">
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                                    <h2>Class:</h2>
                                    {classs?._id ? (
                                        <Link
                                            to={`/admin/classes/${classs?._id}`}
                                            className="text-white uppercase bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                        >
                                            {classs?.name}
                                        </Link>
                                    ) : (
                                        <span className="text-sm text-gray-500 dark:text-gray-400">No class assigned</span>
                                    )}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleTeachers;
