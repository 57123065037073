// Routers.js
import { Route, Routes } from "react-router-dom";
import Admin from "./admin/Admin";
import RouteScroll from "./utils/RouteScroll";
import Authentication from "./protected/Protected";
import Dashboard from "./admin/adminComponents/Dashboard";
// import AdminUsers from "./admin/adminComponents/";
import { useEffect, useState } from "react";
import LazyLoading from "./components/LazyLoad";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser } from "./store/users/usersThunks";
import SignIn from "./pages/auth/Login";
import Classes from "./admin/classes/Classes";
import Users from "./admin/users/Users";
import Students from "./admin/users/childrens/Students";
import SingleStudent from "./admin/users/childrens/SingleStudent";
import SingleTeachers from "./admin/users/childrens/SingleTeacher";
import Teachers from "./admin/users/childrens/Teachers";
import SingleClass from "./admin/classes/childrens/SingleClass";

function Routers() {
  // const { changeRole } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <LazyLoading />;
  }

  return (
    <div>
      <RouteScroll />
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route element={<Authentication />}>
          <Route path="/*" element={<Admin />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="" element={<Dashboard />} />
            <Route path="classes" element={<Classes />} />
            <Route path="classes/:id" element={<SingleClass />} />
            <Route path="users" element={<Users />} />
            <Route path="users/students" element={<Students />} />
            <Route path="users/students/:id" element={<SingleStudent />} />
            <Route path="users/teachers" element={<Teachers />} />
            <Route path="users/teachers/:id" element={<SingleTeachers />} />

            {/* <Route path="users" element={<AdminUsers />} /> */}

          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default Routers;
