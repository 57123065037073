import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchUsers = createAsyncThunk(
  "usersApi/fetchUsers",
  async ({ page, pageSize }) => {
    const response = await axios.get("/users/allUsers", {
      params: {
        page,
        pageSize,
      },
    });
    // console.log("fetchUsers response: ", response.data);
    return {
      users: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalCount: response.data.pagination.totalDocuments,
    };
  }
);

export const fetchStudents = createAsyncThunk(
  "users/fetchStudents",
  async ({ page, pageSize }) => {
    const response = await axios.get("/users/allStudents", {
      params: {
        page,
        pageSize,
      },
    });
    // console.log("fetchUsers response: ", response.data);
    return {
      users: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalCount: response.data.pagination.totalDocuments,
    };
  }
);
export const fetchTeachers = createAsyncThunk(
  "users/fetchTeachers",
  async ({ page, pageSize }) => {
    const response = await axios.get("/users/allTeachers", {
      params: {
        page,
        pageSize,
      },
    });
    // console.log("fetchUsers response: ", response.data);
    return {
      users: response.data.data, // Adjust based on actual response structure
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalCount: response.data.pagination.totalDocuments,
    };
  }
);
export const fetchUserById = createAsyncThunk(
  "usersApi/fetchUserById",
  async (userId) => {
    const response = await axios.get(`/users/allUsers/${userId}`);
    console.log("fetchUserById response: ", response.data);
    return response.data;
  }
);

export const fetchCurrentUser = createAsyncThunk(
  "usersApi/fetchCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/users/currentUser");
      console.log("fetchCurrentUser data: ", response.data);
      return response.data;
    } catch (error) {
      console.error("fetchCurrentUser error: ", error.response);
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchStudentsNotInClass = createAsyncThunk(
  "users/fetchStudentsNotInClass",
  async ({ page = 1, pageSize = 10, search = "" } = {}) => {
    try {
      const response = await axios.get("/users/studentsNotInClass", {
        params: {
          page,
          pageSize,
          search,
        },
      });

      // Handle empty results or no students found
      if (response?.data?.data?.length === 0) {
        return {
          users: [],
          totalPages: 0,
          currentPage: page,
          totalCount: 0,
        };
      }

      return {
        users: response.data.data,
        totalPages: response.data.pagination.totalPages,
        currentPage: response.data.pagination.currentPage,
        totalCount: response.data.pagination.totalDocuments,
      };
    } catch (error) {
      console.error("Error fetching students not in class: ", error);
      throw error; // Rethrow error to be caught by Redux Toolkit's rejected action
    }
  }
);

export const fetchStudentsInClass = createAsyncThunk(
  "users/fetchStudentsInClass",
  async ({ page = 1, pageSize = 10 } = {}) => { // Provide default values
    const response = await axios.get("/users/studentsInClass", {
      params: {
        page,
        pageSize,
      },
    });
    console.log("fetchStudentsInClass response: ", response.data);
    if (response?.data?.data?.length === 0) {
      return {
        users: [],
        totalPages: 0,
        currentPage: page,
        totalCount: 0,
      };
    }



    return {
      users: response.data.data,
      totalPages: response.data.pagination.totalPages,
      currentPage: response.data.pagination.currentPage,
      totalCount: response.data.pagination.totalDocuments,
    };
  }
);
export const addUser = createAsyncThunk(
  "users/addUser",
  async (userData, { rejectWithValue }) => {
    try {
      // Send POST request to backend to add a new user
      const response = await axios.post("/users/addUser", userData);

      // Return the added user data if successful
      return response.data;
    } catch (error) {
      console.error("Error adding user: ", error);
      // Handle any errors and reject the promise with an error message
      return rejectWithValue(error.response?.data || "Failed to add user");
    }
  }
);

export const editUser = createAsyncThunk(
  "users/editUser",
  async ({ userId, userData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/users/editUser/${userId}`, userData);
      return response.data;
    } catch (error) {
      console.error("Error editing user: ", error);
      return rejectWithValue(error.response?.data || "Failed to edit user");
    }
  }
);
