import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchClasses = createAsyncThunk(
  "classes/fetchClasses",
  async ({ branchId, page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      // Make the API request to fetch classes for a specific branch with pagination
      const response = await axios.get(`/classes/branches/${branchId}`, {
        params: {
          page,
          limit: pageSize,
        },
      });

      // Return the fetched classes and pagination details
      return {
        data: response.data.data, // Adjust based on actual response structure
        totalPages: response.data.pagination.totalPages,
        currentPage: response.data.pagination.currentPage,
        totalCount: response.data.pagination.totalDocuments,
      };
    } catch (error) {
      // Handle errors and return a rejected value
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchClassById = createAsyncThunk(
  "classes/fetchClassById",
  async (classId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/classes/${classId}`);
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add a student to a class
export const addStudentToClass = createAsyncThunk(
  "classes/addStudentToClass",
  async ({ classId, branchId, studentId, monthlyFee }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/classes/${classId}/add-student`, {
        branchId,
        studentId,
        monthlyFee,
      });
      return response.data; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Remove a student from a class
export const removeStudentFromClass = createAsyncThunk(
  "classes/removeStudentFromClass",
  async ({ classId, branchId, studentId, monthlyFee }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/classes/${classId}/remove-student`, {
        branchId,
        studentId,
        monthlyFee,
      });
      return response.data; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const addClass = createAsyncThunk(
  "classes/addClass",
  async (newClassData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/classes", newClassData);
      console.log("response", response);
      // return response.data; // Adjust based on your API response structure
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);