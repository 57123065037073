import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { authReducer } from "./auth/authSlice";
// import tournamentSlice from "./tournamentApi/tournamentSlice";
import usersSlice from "./users/usersSlice";
import ClassesSlice from "./classes/classesSlice";
import adminApiSlice from "../services/admin/adminApi";
import apiSlice from "../services/api";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["auth"],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    users: usersSlice,
    classes: ClassesSlice,
    [adminApiSlice.reducerPath]: adminApiSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/FLUSH",
          "persist/PAUSE",
          "persist/PURGE",
          "persist/REGISTER",
        ],
      },
    }).concat(adminApiSlice.middleware, apiSlice.middleware),
  devTools: true,
});

export const persistor = persistStore(store);
