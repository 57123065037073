import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUsers,
  fetchStudents,
  fetchTeachers,
  fetchUserById,
  fetchCurrentUser,
  fetchStudentsNotInClass,
  fetchStudentsInClass,
  addUser,
  editUser,
} from "./usersThunks"; // Adjust the path according to your structure

const initialState = {
  users: {
    data: [],
    currentPage: 1,
    totalPages: 1,
    status: "idle",
    error: null,
    totalCount: null,
  },
  students: {
    data: [],
    currentPage: 1,
    totalPages: 1,
    status: "idle",
    error: null,
    totalCount: null,
  },
  teachers: {
    data: [],
    currentPage: 1,
    totalPages: 1,
    status: "idle",
    error: null,
    totalCount: null,
  },
  singleUser: {
    status: "idle",
    error: null,
    data: null,
  },
  currentUser: {
    status: "idle",
    error: null,
    data: null,
  },
  studentsNotInClass: {
    data: [],
    currentPage: 1,
    totalPages: 1,
    status: "idle",
    error: null,
    totalCount: null,
  },
  studentsInClass: {
    data: [],
    currentPage: 1,
    totalPages: 1,
    status: "idle",
    error: null,
    totalCount: null,
  },
  addUserStatus: "idle", // Status for adding a user
  addUserError: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.users.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Users
      .addCase(fetchUsers.pending, (state) => {
        state.users.status = "loading";
        state.users.error = null; // Reset error state when loading starts
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users.status = "succeeded";
        state.users.data = action.payload.users;
        state.users.totalPages = action.payload.totalPages;
        state.users.currentPage = action.payload.currentPage;
        state.users.totalCount = action.payload.totalCount;
        state.users.error = null; // Reset error state on success
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.users.status = "failed";
        state.users.error = action.error.message || "Failed to fetch users.";
      })

      // Fetch Students
      .addCase(fetchStudents.pending, (state) => {
        state.students.status = "loading";
        state.students.error = null;
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        state.students.status = "succeeded";
        state.students.data = action.payload.users;
        state.students.totalPages = action.payload.totalPages;
        state.students.totalCount = action.payload.totalCount;
        state.students.currentPage = action.payload.currentPage;
        state.students.error = null;
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.students.status = "failed";
        state.students.error = action.error.message || "Failed to fetch students.";
      })

      // Fetch Teachers
      .addCase(fetchTeachers.pending, (state) => {
        state.teachers.status = "loading";
        state.teachers.error = null;
      })
      .addCase(fetchTeachers.fulfilled, (state, action) => {
        state.teachers.status = "succeeded";
        state.teachers.data = action.payload.users;
        state.teachers.totalPages = action.payload.totalPages;
        state.teachers.totalCount = action.payload.totalCount;
        state.teachers.currentPage = action.payload.currentPage;
        state.teachers.error = null;
      })
      .addCase(fetchTeachers.rejected, (state, action) => {
        state.teachers.status = "failed";
        state.teachers.error = action.error.message || "Failed to fetch teachers.";
      })

      // Fetch User by ID
      .addCase(fetchUserById.pending, (state) => {
        state.singleUser.status = "loading";
        state.singleUser.error = null;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.singleUser.status = "succeeded";
        state.singleUser.data = action.payload;
        state.singleUser.error = null;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.singleUser.status = "failed";
        state.singleUser.error = action.error.message || "Failed to fetch user by ID.";
      })

      // Fetch Current User
      .addCase(fetchCurrentUser.pending, (state) => {
        state.currentUser.status = "loading";
        state.currentUser.error = null;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.currentUser.status = "succeeded";
        state.currentUser.data = action.payload;
        state.currentUser.error = null;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.currentUser.status = "failed";
        state.currentUser.error = action.error.message || "Failed to fetch current user.";
      })

      // Fetch Students Not in Class
      .addCase(fetchStudentsNotInClass.pending, (state) => {
        state.studentsNotInClass.status = "loading";
        state.studentsNotInClass.error = null;
      })
      .addCase(fetchStudentsNotInClass.fulfilled, (state, action) => {
        state.studentsNotInClass.status = "succeeded";
        state.studentsNotInClass.data = action.payload.users;
        state.studentsNotInClass.totalPages = action.payload.totalPages;
        state.studentsNotInClass.currentPage = action.payload.currentPage;
        state.studentsNotInClass.totalCount = action.payload.totalCount;
        state.studentsNotInClass.error = null;
      })
      .addCase(fetchStudentsNotInClass.rejected, (state, action) => {
        state.studentsNotInClass.status = "failed";
        state.studentsNotInClass.error = action.error.message || "Failed to fetch students not in class.";
      })

      // Add User
      .addCase(addUser.pending, (state) => {
        state.addUserStatus = "loading";
        state.addUserError = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.addUserStatus = "succeeded";
        state.users.data.push(action.payload); // Add the new user to the existing users list
        state.addUserError = null;
      })

      .addCase(addUser.rejected, (state, action) => {
        state.addUserStatus = "failed";
        state.addUserError = action.error.message;
      })
      // Fetch Students In Class
      .addCase(fetchStudentsInClass.pending, (state) => {
        state.studentsInClass.status = "loading";
        state.studentsInClass.error = null;
      })
      .addCase(fetchStudentsInClass.fulfilled, (state, action) => {
        state.studentsInClass.status = "succeeded";
        state.studentsInClass.data = action.payload.users;
        state.studentsInClass.totalPages = action.payload.totalPages;
        state.studentsInClass.currentPage = action.payload.currentPage;
        state.studentsInClass.totalCount = action.payload.totalCount;
        state.studentsInClass.error = null;
      })
      .addCase(fetchStudentsInClass.rejected, (state, action) => {
        state.studentsInClass.status = "failed";
        state.studentsInClass.error = action.error.message || "Failed to fetch students in class.";
      })
      // Add Edit User Cases
      .addCase(editUser.pending, (state) => {
        state.singleUser.status = "loading";
        state.singleUser.error = null;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.singleUser.status = "succeeded";
        const updatedUserIndex = state.users.data.findIndex(user => user._id === action.payload._id);
        if (updatedUserIndex !== -1) {
          state.users.data[updatedUserIndex] = action.payload; // Update user in the list
        }
        state.singleUser.data = action.payload; // Update single user data
        state.singleUser.error = null;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.singleUser.status = "failed";
        state.singleUser.error = action.error.message || "Failed to edit user.";
      });
  },
});

export const { setCurrentPage } = usersSlice.actions;
export default usersSlice.reducer;
